import bag12 from '../image/SARAH/1.png';
import bag1 from '../image/SARAH/2.png';
import bag13 from '../image/SARAH/3.png';
import bag14 from '../image/SARAH/4.png';
import bag15 from '../image/SARAH/5.png';
import bag16 from '../image/SARAH/6.png';

import bag2 from '../image/OLIVIA/Blue/bag2.webp';
import bag22 from '../image/OLIVIA/Blue/bag22.webp';
import bag23 from '../image/OLIVIA/Blue/bag23.webp';
import bag24 from '../image/OLIVIA/Blue/bag24.webp';
import bag25 from '../image/OLIVIA/Blue/bag25.webp';
import bag26 from '../image/OLIVIA/Blue/bag26.webp';

import bag32 from '../image/OLIVIA/Chocolate/1.png';
import bag3 from '../image/OLIVIA/Chocolate/2.png';
import bag33 from '../image/OLIVIA/Chocolate/3.png';
import bag34 from '../image/OLIVIA/Chocolate/4.png';
import bag35 from '../image/OLIVIA/Chocolate/5.png';
import bag36 from '../image/OLIVIA/Chocolate/6.png';
/*
import bag4 from "../image/BATTY_М/bag4.webp"
import bag42 from "../image/BATTY_М/bag42.webp"
import bag43 from "../image/BATTY_М/bag43.webp"
import bag44 from "../image/BATTY_М/bag44.webp"
import bag45 from "../image/BATTY_М/bag45.webp"
import bag46 from "../image/BATTY_М/bag46.webp"
*/
import bag5 from '../image/BATTY_S/bag5.webp';
import bag52 from '../image/BATTY_S/bag52.webp';
import bag53 from '../image/BATTY_S/bag53.webp';
import bag54 from '../image/BATTY_S/bag54.webp';
import bag55 from '../image/BATTY_S/bag55.webp';
import bag56 from '../image/BATTY_S/bag56.webp';

import bag6 from '../image/ROSE/bag6.webp';
import bag62 from '../image/ROSE/bag62.webp';
import bag63 from '../image/ROSE/bag63.webp';
import bag64 from '../image/ROSE/bag64.webp';
import bag65 from '../image/ROSE/bag65.webp';
import bag66 from '../image/ROSE/bag66.webp';

import bag7 from '../image/MIA/1.png';
import bag72 from '../image/MIA/2.png';
import bag73 from '../image/MIA/3.png';
import bag74 from '../image/MIA/4.png';
// import bag75 from '../image/MIA/bag75.png';
// import bag76 from '../image/MIA/bag76.png';

import bag8 from '../image/Molly/bag8.png';
import bag82 from '../image/Molly/bag82.png';
import bag83 from '../image/Molly/bag83.png';
import bag84 from '../image/Molly/bag84.png';
import bag85 from '../image/Molly/bag85.png';
import bag86 from '../image/Molly/bag86.png';

import bag10 from '../image/Belt/1.png';
import bag10_2 from '../image/Belt/2.png';
import bag10_3 from '../image/Belt/3.png';
import bag10_4 from '../image/Belt/4.png';
import bag10_5 from '../image/Belt/5.png';
import bag10_6 from '../image/Belt/6.png';

import bag11 from '../image/CosmeticBag/bag11.png';
import bag11_2 from '../image/CosmeticBag/bag11_2.png';
import bag11_3 from '../image/CosmeticBag/bag11_3.png';
import bag11_4 from '../image/CosmeticBag/bag11_4.png';
import bag11_5 from '../image/CosmeticBag/bag11_5.png';
import bag11_6 from '../image/CosmeticBag/bag11_6.png';

const SET_MODEL = 'SET-MODEL';
const SET_LOCALE_STORAGE = 'SET-LOCALE-STORAGE';
const SET_CART_LIST = 'SET-CART-LIST';
const GET_LOCALE_STORAGE = 'GET-LOCALE-STORAGE';
const PLUS_MODEL_COUNT = 'PLUS-MODEL-COUNT';
const PLUS_CART_MODEL = 'PLUS-CART-MODEL';
const CARD_ID_PLUS = 'CARD_ID_PLUS';
const DELL_CART_ITEM = 'DELL-CART-ITEM';
const RESULT_SUMM_FUNC = 'RESULT-SUMM-FUNC';
const OPEN_ADDED_PRODUCT = 'OPEN-ADDED-PRODUCT';
const DOUBLE_ADD_MODEL = 'DOUBLE-ADD-MODEL';
const CHANGE_COLOR_IMAGE = 'CHANGE-COLOR-IMAGE';
const CHANGE_SLIDER_MAIN_IMAGE = 'CHANGE-SLIDER-MAIN-IMAGE';
const SET_LINK = 'SET-LINK';

let initialState = {
  categories: [
    {
      id: 1,
      categories_title: 'SAMPLE SALE',
      background_image: bag82,
      button_link: '/catalog',
    },
    {
      id: 2,
      categories_title: 'ПОШИВ НА ЗАКАЗ',
      background_image: bag2,
      button_link: '/castom',
    },
    {
      id: 3,
      categories_title: 'ПОДАРОЧНЫЕ СЕРТИФИКАТЫ',
      background_image: bag5,
      button_link: '/sertificates',
    },
  ],
  bags_model: [
    {
      id: 0,
      card_image: bag1,
      card_image2: bag12,
      card_name: 'RITA',
      price: '20000',
      price_active: '10000',
      card_color: {
        color_name: 'Хаки',
        color_code: '#7c7e7b',
      },
      count: 1,
      description: {
        size: 'Размеры 42*25*12 см',
        material: 'Натуральная кожа',
        accessories: 'Итальянская фурнитура цвета серебро',
        lining: 'Текстильный подклад',
        text: 'Внутри одно большое основное отделение и два накладных кармана из кожи, способ открывания – молния.',
        sub_description: 'В комплекте пыльник, фирменный пакет.',
        weight: 'Вес ~ 500 гр.',
      },
      slider_image: [
        { image: bag1 },
        { image: bag12 },
        { image: bag13 },
        { image: bag14 },
        { image: bag15 },
        { image: bag16 },
      ],
    },
    {
      id: 1,
      card_image: bag82,
      card_image2: bag8,
      card_name: 'MOLLY',
      price: '5500',
      price_active: '',
      card_color: {
        color_name: 'Blue metalic',
        color_code: '#6c7c95',
      },
      count: 1,
      description: {
        size: 'Размеры 28*20*12 см',
        material: 'Натуральная кожа',
        accessories: 'Итальянская фурнитура цвета серебро',
        lining: 'Способ открывания – молния',
        text: 'Внимание! В продаже один экземпляр. Модель снята с пошива. Есть дефект.',
        sub_description:
          'Текстильный подклад. Внутри два отделения и карман на молнии. Съемная ручка-ремень.',
        weight: 'Вес ~ 400 гр.',
      },
      slider_image: [
        { image: bag82 },
        { image: bag8 },
        { image: bag83 },
        { image: bag84 },
        { image: bag85 },
        { image: bag86 },
      ],
    },
    {
      id: 2,
      card_image: bag3,
      card_image2: bag32,
      card_name: 'ROSA',
      price: '21000',
      price_active: '10000',
      card_color: {
        color_name: 'Темно-лиловый',
        color_code: '#655c5f',
      },
      count: 1,
      description: {
        size: 'Размеры 33*28*15 см',
        material:
          'Натуральная винтажная кожа с эффектом Pull-up. Нежная, но легко царапается, придавая уникальный рисунок и характер.',
        accessories: 'Итальянская фурнитура цвета серебро',
        lining: 'Подклад - фибра',
        text: 'Внутри одно большое основное отделение и два накладных кармана из кожи, способ открывания – молния. В комплекте пыльник, фирменный пакет.',
        sub_description: 'Внимание! У данного образца отсутствуют крепления под плечевой ремень.',
        weight: 'Вес ~ 500 гр.',
      },
      slider_image: [
        { image: bag3 },
        { image: bag32 },
        { image: bag33 },
        { image: bag34 },
        { image: bag35 },
        { image: bag36 },
      ],
    } /*
		{
			id: 3,
			card_image: bag4,
			card_image2: bag42,
			card_name: "BATTY M",
			price: "18900",
			card_color: {
				color_name: "Taupe",
				color_code: "#83766c"
			},
			count: 1,
			description: {
				size: 'Размеры 22*18*10 см',
				material: 'Натуральная кожа',
				accessories: 'Итальянская фурнитура цвета золото',
				lining: 'Текстильный подклад',
				text: 'Внутри одно основное отделение и карман на молнии, способ открывания – молния',
				sub_description: "В комплекте плечевой регулируемый ремень длиной 120 см, пыльник, пошив в любом цвете из Палитры "
			},
			slider_image: [
				{ image: bag4 },
				{ image: bag42 },
				{ image: bag43 },
				{ image: bag44 },
				{ image: bag45 },
				{ image: bag46 },
			],
		},*/,

    /*{
			id: 4,
			card_image: bag6,
			card_image2: bag62,
			card_name: "ROSE",
			price: "21000",
			price_active: "",
			card_color: {
				color_name: "Taupe",
				color_code: "#b9a281"
			},
			count: 1,
			description: {
				size: 'Размеры 31,5*27,5*15 см',
				material: 'Натуральная кожа',
				accessories: 'Итальянская фурнитура цвета серебро',
				lining: 'Подклад - фибра',
				text: 'Внутри одно основное отделение и накладной карман, способ открывания – молния',
				sub_description: "В комплекте пыльник",
				weight: "Вес ~ 500 гр."
			},
			slider_image: [
				{ image: bag6 },
				{ image: bag62 },
				{ image: bag63 },
				{ image: bag64 },
				{ image: bag65 },
				{ image: bag66 },
			],
		},*/
    {
      id: 3,
      card_image: bag10,
      card_image2: bag10_2,
      card_name: 'SARAH',
      price: '18000',
      price_active: '8000',
      card_color: {
        color_name: 'Черный',
        color_code: '#000',
      },
      count: 1,
      description: {
        size: 'Размеры 28*17*8 см',
        material:
          'Натуральная винтажная кожа с эффектом Pull-up. Нежная, но легко царапается, придавая уникальный рисунок и характер.',
        accessories: 'Итальянская фурнитура цвета серебро',
        lining: 'Подклад - фибра',
        text: 'Внутри одно основное отделение и один накладной карман, способ открывания – клапан на магнитах.',
        sub_description: 'В комплекте пыльник, фирменный пакет.',
        weight: 'Вес ~ 400 гр.',
      },
      slider_image: [
        { image: bag10 },
        { image: bag10_2 },
        { image: bag10_3 },
        { image: bag10_4 },
        { image: bag10_5 },
        { image: bag10_6 },
      ],
    },
    {
      id: 4,
      card_image: bag7,
      card_image2: bag72,
      card_name: 'MIA',
      price: '6500',
      price_active: '3250',
      card_color: {
        color_name: 'Хаки',
        color_code: '#7c7e7b',
      },
      count: 1,
      description: {
        size: 'Размеры 24*12см',
        material: 'Китайская фурнитура.',
        accessories: 'Способ открывания – кулиска.',
        lining: 'Подклад - фибра',
        text: 'Внутри одно основное отделение и накладной карман из кожи для пластиковых карт.',
        sub_description: 'Натуральная кожа',
        weight: 'Вес ~ 200 гр.',
      },
      slider_image: [
        { image: bag7 },
        { image: bag72 },
        { image: bag73 },
        { image: bag74 },
        // { image: bag11_5 },
        // { image: bag11_6 },
      ],
    },
    {
      id: 5,
      card_image: bag11,
      card_image2: bag11_2,
      card_name: 'Cosmetic bag ',
      price: '800',
      price_active: '',
      card_color: {
        color_name: 'Black',
        color_code: '#000',
      },
      count: 1,
      description: {
        size: 'Размеры 24*12см',
        material: 'Китайская фурнитура.',
        accessories: 'Способ открывания – молния',
        lining: 'Текстильный подклад. ',
        text: 'Внимание! В продаже несколько экземпляров. Модель снята с пошива. ',
        sub_description: 'Натуральная кожа',
        weight: 'Вес ~ 100 гр.',
      },
      slider_image: [
        { image: bag11 },
        { image: bag11_2 },
        { image: bag11_3 },
        { image: bag11_4 },
        { image: bag11_5 },
        { image: bag11_6 },
      ],
    },
    {},
    {},
  ],
  slider: {
    image: '',
    index: 0,
  },
  selected_model: [{}],
  cart_list: [],
  model_count: 0,
  cardId: 0,
  result_summ: 0,
  added_product: 'Header_card_info_close__AB7ux',
  check_open_added_product: true,
  card_link: '',
  link: '/catalog',
};
const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODEL:
      state.selected_model = action.newSelectedModel;
      localStorage.setItem('card', JSON.stringify(state.selected_model));
      return state;
    case SET_CART_LIST:
      state.cart_list.push(action.newCartItem);
      localStorage.setItem('cart_list', JSON.stringify(state.cart_list));
      return state;
    case GET_LOCALE_STORAGE:
      JSON.parse(localStorage.getItem('card'));
      return state;
    case PLUS_MODEL_COUNT:
      state.model_count = action.newModelCount;
      localStorage.setItem('cardCount', JSON.stringify(state.model_count));
      return state;
    case PLUS_CART_MODEL:
      state.cart_list[action.newCardIdArray].count = action.newCount;
      localStorage.setItem('cart_list', JSON.stringify(state.cart_list));
      return state;
    case CARD_ID_PLUS:
      state.cardId = action.newCardId;
      localStorage.setItem('card_id', JSON.stringify(state.cardId));
      return state;
    case DELL_CART_ITEM:
      state.cart_list[action.newCardId].count = 1;
      state.cart_list.splice(action.newCardId, 1);
      localStorage.setItem('cart_list', JSON.stringify(state.cart_list));
      return state;
    case RESULT_SUMM_FUNC:
      state.result_summ = action.newSumm;
      return state;
    case OPEN_ADDED_PRODUCT:
      state.added_product = action.new_classe_added_product;
      state.check_open_added_product = action.check;
      return state;
    case DOUBLE_ADD_MODEL:
      state.cart_list[action.newCard].count = action.newCountList;
      localStorage.setItem('cart_list', JSON.stringify(state.cart_list));
      return state;
    case CHANGE_COLOR_IMAGE:
      state.bags_model[action.id].card_image = action.new_card_image;
      state.bags_model[action.id].card_image2 = action.new_card_image2;
      return state;
    case CHANGE_SLIDER_MAIN_IMAGE:
      state.slider.image = action.new_image;
      state.slider.index = action.new_index;
      localStorage.setItem('slider', JSON.stringify(state.slider));
      return state;
    case SET_LINK:
      state.card_link = action.new_link;
      return state;
    default:
      return state;
  }
};
export const setSelectedModelAC = (object_model) => ({
  type: SET_MODEL,
  newSelectedModel: object_model,
});
export const setCartListAC = (cart_item) => ({ type: SET_CART_LIST, newCartItem: cart_item });
export const getLocaleStorageAC = () => ({ type: GET_LOCALE_STORAGE });
export const plusModelCountAC = (count_card) => ({
  type: PLUS_MODEL_COUNT,
  newModelCount: count_card,
});
export const plusCartCountAC = (count_cart, idArr) => ({
  type: PLUS_CART_MODEL,
  newCount: count_cart,
  newCardIdArray: idArr,
});
export const cardIdPlusAC = (cardIdCount) => ({ type: CARD_ID_PLUS, newCardId: cardIdCount });
export const delCardIdAC = (cardIdCount) => ({ type: DELL_CART_ITEM, newCardId: cardIdCount });
export const resultSummFuncAC = (summ) => ({ type: RESULT_SUMM_FUNC, newSumm: summ });
export const openAddedProductAC = (new_classe, new_check) => ({
  type: OPEN_ADDED_PRODUCT,
  new_classe_added_product: new_classe,
  check: new_check,
});
export const doubleAddModelAC = (card_id, new_count) => ({
  type: DOUBLE_ADD_MODEL,
  newCard: card_id,
  newCountList: new_count,
});
export const changeColorImageAC = (id, new_image, new_image2) => ({
  type: CHANGE_COLOR_IMAGE,
  id: id,
  new_card_image: new_image,
  new_card_image2: new_image2,
});
export const changeSliderMainImageAC = (new_image, index) => ({
  type: CHANGE_SLIDER_MAIN_IMAGE,
  new_image: new_image,
  new_index: index,
});
export const setLinkAC = (link) => ({ type: SET_LINK, new_link: link });
export default catalogReducer;
