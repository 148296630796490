import model_1 from '../image/SARAH/card_castom.webp';
import model_1_asphalt from '../image/CASTOM/SARAH/asphalt.png';
import model_1_bardic from '../image/CASTOM/SARAH/bardic.png';
import model_1_biege from '../image/CASTOM/SARAH/card_beige.jpg';
import model_1_black from '../image/CASTOM/SARAH/card_black.jpg';
import model_1_blue from '../image/CASTOM/SARAH/blue.png';
import model_1_brown from '../image/CASTOM/SARAH/brown.png';
import model_1_lavender from '../image/CASTOM/SARAH/lavender.png';
import model_1_lemon from '../image/CASTOM/SARAH/lemon.png';
import model_1_menthol from '../image/CASTOM/SARAH/menthol.png';
import model_1_milk from '../image/CASTOM/SARAH/milk.png';
import model_1_red from '../image/CASTOM/SARAH/red.png';
import model_1_taup from '../image/CASTOM/SARAH/taup.png';
import model_1_violet from '../image/CASTOM/SARAH/violet.png';
import model_1_white from '../image/CASTOM/SARAH/white.png';

import model_2 from '../image/OLIVIA/Blue/card_castom.webp';
import model_2_1_asphalt from '../image/CASTOM/OLIVIA/new/olivia_asphalt_1.png';
import model_2_2_asphalt from '../image/CASTOM/OLIVIA/new/olivia_asphalt_2.png';
import model_2_1_bardic from '../image/CASTOM/OLIVIA/new/olivia_bardic_1.png';
import model_2_2_bardic from '../image/CASTOM/OLIVIA/new/olivia_bardic_2.png';
import model_2_1_beige from '../image/CASTOM/OLIVIA/new/olivia_beige_1.png';
import model_2_2_beige from '../image/CASTOM/OLIVIA/new/olivia_beige_2.png';
import model_2_1_black from '../image/CASTOM/OLIVIA/new/olivia_black_1.png';
import model_2_2_black from '../image/CASTOM/OLIVIA/new/olivia_black_2.png';
import model_2_1_blue from '../image/CASTOM/OLIVIA/new/olivia_blue_1.png';
import model_2_2_blue from '../image/CASTOM/OLIVIA/new/olivia_blue_2.png';
import model_2_1_brown from '../image/CASTOM/OLIVIA/new/olivia_brown_1.png';
import model_2_2_brown from '../image/CASTOM/OLIVIA/new/olivia_brown_2.png';
import model_2_1_lavender from '../image/CASTOM/OLIVIA/new/olivia_lavender_1.png';
import model_2_2_lavender from '../image/CASTOM/OLIVIA/new/olivia_lavender_2.png';
import model_2_1_lemon from '../image/CASTOM/OLIVIA/new/olivia_lemon_1.png';
import model_2_2_lemon from '../image/CASTOM/OLIVIA/new/olivia_lemon_2.png';
import model_2_1_menthol from '../image/CASTOM/OLIVIA/new/olivia_menthol_1.png';
import model_2_2_menthol from '../image/CASTOM/OLIVIA/new/olivia_menthol_2.png';
import model_2_1_milk from '../image/CASTOM/OLIVIA/new/olivia_milk_1.png';
import model_2_2_milk from '../image/CASTOM/OLIVIA/new/olivia_milk_2.png';
import model_2_1_red from '../image/CASTOM/OLIVIA/new/olivia_red_1.png';
import model_2_2_red from '../image/CASTOM/OLIVIA/new/olivia_red_2.png';
import model_2_1_taup from '../image/CASTOM/OLIVIA/new/olivia_taup_1.png';
import model_2_2_taup from '../image/CASTOM/OLIVIA/new/olivia_taup_2.png';
import model_2_1_violet from '../image/CASTOM/OLIVIA/new/olivia_violet_1.png';
import model_2_2_violet from '../image/CASTOM/OLIVIA/new/olivia_violet_2.png';
import model_2_1_white from '../image/CASTOM/OLIVIA/new/olivia_white_1.png';
import model_2_2_white from '../image/CASTOM/OLIVIA/new/olivia_white_2.png';

import no_picture from '../image/CASTOM/PALETTE/no_picture.png';

import model_3 from '../image/BATTY_М/card_castom.webp';
import model_3_asphalt from '../image/CASTOM/BATTY/batty_asphalt.webp';
import model_3_bardic from '../image/CASTOM/BATTY/batty_baric.webp';
import model_3_beige from '../image/CASTOM/BATTY/batty_beige.webp';
import model_3_black from '../image/CASTOM/BATTY/batty_black.webp';
import model_3_blue from '../image/CASTOM/BATTY/batty_blue.webp';
import model_3_brown from '../image/CASTOM/BATTY/batty_brown.webp';
import model_3_lavender from '../image/CASTOM/BATTY/batty_lavender.webp';
import model_3_lemon from '../image/CASTOM/BATTY/batty_lemon.webp';
import model_3_menthol from '../image/CASTOM/BATTY/batty_menthol.webp';
import model_3_milk from '../image/CASTOM/BATTY/batty_milk.webp';
import model_3_red from '../image/CASTOM/BATTY/batty_red.webp';
import model_3_taup from '../image/CASTOM/BATTY/batty_taup.webp';
import model_3_violet from '../image/CASTOM/BATTY/batty_violet.webp';
import model_3_white from '../image/CASTOM/BATTY/batty_white.webp';

import model_4 from '../image/CASTOM/MIA/card_castom.webp';
import model_4_asphalt from '../image/CASTOM/MIA/mia_asphalt.webp';
import model_4_bardic from '../image/CASTOM/MIA/mia_bardic.webp';
import model_4_beige from '../image/CASTOM/MIA/mia_beige.webp';
import model_4_black from '../image/CASTOM/MIA/mia_black.webp';
import model_4_blue from '../image/CASTOM/MIA/mia_blue.webp';
import model_4_brown from '../image/CASTOM/MIA/mia_brown.webp';
import model_4_lavender from '../image/CASTOM/MIA/mia_lavender.webp';
import model_4_lemon from '../image/CASTOM/MIA/mia_lemon.webp';
import model_4_menthol from '../image/CASTOM/MIA/mia_menthol.webp';
import model_4_milk from '../image/CASTOM/MIA/mia_milk.webp';
import model_4_red from '../image/CASTOM/MIA/mia_red.webp';
import model_4_taup from '../image/CASTOM/MIA/mia_taup.webp';
import model_4_violet from '../image/CASTOM/MIA/mia_violet.webp';
import model_4_white from '../image/CASTOM/MIA/mia_white.webp';

import model_5 from '../image/CASTOM/ROSE/card_castom.jpg';
import model_5_asphalt from '../image/CASTOM/ROSE/rose_asphalt.png';
import model_5_bardic from '../image/CASTOM/ROSE/rose_bardic.png';
import model_5_beige from '../image/CASTOM/ROSE/rose_beige.png';
import model_5_black from '../image/CASTOM/ROSE/rose_black.png';
import model_5_blue from '../image/CASTOM/ROSE/rose_blue.png';
import model_5_brown from '../image/CASTOM/ROSE/rose_brown.png';
import model_5_lavender from '../image/CASTOM/ROSE/rose_lavender.png';
import model_5_lemon from '../image/CASTOM/ROSE/rose_lemon.png';
import model_5_menthol from '../image/CASTOM/ROSE/rose_menthol.png';
import model_5_milk from '../image/CASTOM/ROSE/rose_milk.png';
import model_5_red from '../image/CASTOM/ROSE/rose_red.png';
import model_5_taup from '../image/CASTOM/ROSE/rose_taup.png';
import model_5_violet from '../image/CASTOM/ROSE/rose_violet.png';
import model_5_white from '../image/CASTOM/ROSE/rose_white.png';

import model_6 from '../image/CASTOM/BACKPACK/card_castom.webp';
import model_6_asphalt from '../image/CASTOM/BACKPACK/back_asphalt.webp';
import model_6_bardic from '../image/CASTOM/BACKPACK/back_bardic.webp';
import model_6_beige from '../image/CASTOM/BACKPACK/back_beige.webp';
import model_6_black from '../image/CASTOM/BACKPACK/back_black.webp';
import model_6_blue from '../image/CASTOM/BACKPACK/back_blue.webp';
import model_6_brown from '../image/CASTOM/BACKPACK/back_brown.webp';
import model_6_lavender from '../image/CASTOM/BACKPACK/back_lavender.webp';
import model_6_lemon from '../image/CASTOM/BACKPACK/back_lemon.webp';
import model_6_menthol from '../image/CASTOM/BACKPACK/back_menthol.webp';
import model_6_milk from '../image/CASTOM/BACKPACK/back_milk.webp';
import model_6_red from '../image/CASTOM/BACKPACK/back_red.webp';
import model_6_taup from '../image/CASTOM/BACKPACK/back_taup.webp';
import model_6_violet from '../image/CASTOM/BACKPACK/back_violet.webp';
import model_6_white from '../image/CASTOM/BACKPACK/back_white.webp';

import model_7_card_castom from '../image/CASTOM/RITA/rita_card_castom.png';
import model_7 from '../image/CASTOM/RITA/rita.png';

import model_8_card_castom from '../image/CASTOM/ANNA/anna_card_castom.png';
import model_8 from '../image/CASTOM/ANNA/anna.png';

import castom_1 from '../image/CASTOM/PALETTE/asphalt.jpg';
import castom_2 from '../image/CASTOM/PALETTE/bardic.jpg';
import castom_3 from '../image/CASTOM/PALETTE/beige.jpg';
import castom_4 from '../image/CASTOM/PALETTE/black.jpg';
import castom_5 from '../image/CASTOM/PALETTE/blue.jpg';
import castom_6 from '../image/CASTOM/PALETTE/brown.jpg';
import castom_7 from '../image/CASTOM/PALETTE/lavender.jpg';
import castom_8 from '../image/CASTOM/PALETTE/lemon.jpg';
import castom_9 from '../image/CASTOM/PALETTE/menthol.jpg';
import castom_10 from '../image/CASTOM/PALETTE/milk.jpg';
import castom_11 from '../image/CASTOM/PALETTE/red.jpg';
import castom_12 from '../image/CASTOM/PALETTE/taup.jpg';
import castom_13 from '../image/CASTOM/PALETTE/violet.jpg';
import castom_14 from '../image/CASTOM/PALETTE/white.jpg';

import furniture_1 from '../image/CASTOM/Furniture/dark nickel.webp';
import furniture_2 from '../image/CASTOM/Furniture/gold.webp';
import furniture_3 from '../image/CASTOM/Furniture/silver.webp';

const CHANGE_MODEL = 'CHANGE-MODEL';
const CHANGE_COLOR = 'CHANGE-COLOR';
const CHANGE_COLOR_DETAIL = 'CHANGE-COLOR-DETAIL';
const CHANGE_FURNITURE_DETAIL = 'CHANGE-FURNITURE-DETAIL';
const CHANGE_SIZE = 'CHANGE-SIZE';

const CHECK_FORM = 'CHECK-FORM';

let initialState = {
  models: [
    {
      name: 'SARAH',
      model: model_1,
      details_block: 'none',
      size_block: 'none',
      price: 18400,
      dimension: '28*17*8 СМ',
      colors: [
        {
          color_main: model_1_asphalt,
          color_details: no_picture,
        },
        {
          color_main: model_1_bardic,
          color_details: no_picture,
        },
        {
          color_main: model_1_biege,
          color_details: no_picture,
        },
        {
          color_main: model_1_black,
          color_details: no_picture,
        },
        {
          color_main: model_1_blue,
          color_details: no_picture,
        },
        {
          color_main: model_1_brown,
          color_details: no_picture,
        },
        {
          color_main: model_1_lavender,
          color_details: no_picture,
        },
        {
          color_main: model_1_lemon,
          color_details: no_picture,
        },
        {
          color_main: model_1_menthol,
          color_details: no_picture,
        },
        {
          color_main: model_1_milk,
          color_details: no_picture,
        },
        {
          color_main: model_1_red,
          color_details: no_picture,
        },
        {
          color_main: model_1_taup,
          color_details: no_picture,
        },
        {
          color_main: model_1_violet,
          color_details: no_picture,
        },
        {
          color_main: model_1_white,
          color_details: no_picture,
        },
      ],
    },
    {
      name: 'OLIVIA',
      model: model_2,
      details_block: 'block',
      size_block: 'none',
      price: 18800,
      dimension: '22*18*10 СМ',
      colors: [
        {
          color_main: model_2_2_asphalt,
          color_details: model_2_1_asphalt,
        },
        {
          color_main: model_2_2_bardic,
          color_details: model_2_1_bardic,
        },
        {
          color_main: model_2_2_beige,
          color_details: model_2_1_beige,
        },
        {
          color_main: model_2_2_black,
          color_details: model_2_1_black,
        },
        {
          color_main: model_2_2_blue,
          color_details: model_2_1_blue,
        },
        {
          color_main: model_2_2_brown,
          color_details: model_2_1_brown,
        },
        {
          color_main: model_2_2_lavender,
          color_details: model_2_1_lavender,
        },
        {
          color_main: model_2_2_lemon,
          color_details: model_2_1_lemon,
        },
        {
          color_main: model_2_2_menthol,
          color_details: model_2_1_menthol,
        },
        {
          color_main: model_2_2_milk,
          color_details: model_2_1_milk,
        },
        {
          color_main: model_2_2_red,
          color_details: model_2_1_red,
        },
        {
          color_main: model_2_2_taup,
          color_details: model_2_1_taup,
        },
        {
          color_main: model_2_2_violet,
          color_details: model_2_1_violet,
        },
        {
          color_main: model_2_2_white,
          color_details: model_2_1_white,
        },
      ],
    },
    {
      name: 'BATTY',
      model: model_3,
      details_block: 'none',
      size_block: 'block',
      price: 17900,
      dimension: '24*12*12 СМ',
      colors: [
        {
          color_main: model_3_asphalt,
          color_details: no_picture,
        },
        {
          color_main: model_3_bardic,
          color_details: no_picture,
        },
        {
          color_main: model_3_beige,
          color_details: no_picture,
        },
        {
          color_main: model_3_black,
          color_details: no_picture,
        },
        {
          color_main: model_3_blue,
          color_details: no_picture,
        },
        {
          color_main: model_3_brown,
          color_details: no_picture,
        },
        {
          color_main: model_3_lavender,
          color_details: no_picture,
        },
        {
          color_main: model_3_lemon,
          color_details: no_picture,
        },
        {
          color_main: model_3_menthol,
          color_details: no_picture,
        },
        {
          color_main: model_3_milk,
          color_details: no_picture,
        },
        {
          color_main: model_3_red,
          color_details: no_picture,
        },
        {
          color_main: model_3_taup,
          color_details: no_picture,
        },
        {
          color_main: model_3_violet,
          color_details: no_picture,
        },
        {
          color_main: model_3_white,
          color_details: no_picture,
        },
      ],
    },
    {
      name: 'MIA',
      model: model_4,
      details_block: 'none',
      size_block: 'none',
      price: 6500,
      dimension: '24*12 СМ',
      colors: [
        {
          color_main: model_4_asphalt,
          color_details: no_picture,
        },
        {
          color_main: model_4_bardic,
          color_details: no_picture,
        },
        {
          color_main: model_4_beige,
          color_details: no_picture,
        },
        {
          color_main: model_4_black,
          color_details: no_picture,
        },
        {
          color_main: model_4_blue,
          color_details: no_picture,
        },
        {
          color_main: model_4_brown,
          color_details: no_picture,
        },
        {
          color_main: model_4_lavender,
          color_details: no_picture,
        },
        {
          color_main: model_4_lemon,
          color_details: no_picture,
        },
        {
          color_main: model_4_menthol,
          color_details: no_picture,
        },
        {
          color_main: model_4_milk,
          color_details: no_picture,
        },
        {
          color_main: model_4_red,
          color_details: no_picture,
        },
        {
          color_main: model_4_taup,
          color_details: no_picture,
        },
        {
          color_main: model_4_violet,
          color_details: no_picture,
        },
        {
          color_main: model_4_white,
          color_details: no_picture,
        },
      ],
    },
    {
      name: 'ROSE',
      model: model_5,
      details_block: 'none',
      size_block: 'none',
      price: 21000,
      dimension: '33*28*15 СМ',
      colors: [
        {
          color_main: model_5_asphalt,
          color_details: no_picture,
        },
        {
          color_main: model_5_bardic,
          color_details: no_picture,
        },
        {
          color_main: model_5_beige,
          color_details: no_picture,
        },
        {
          color_main: model_5_black,
          color_details: no_picture,
        },
        {
          color_main: model_5_blue,
          color_details: no_picture,
        },
        {
          color_main: model_5_brown,
          color_details: no_picture,
        },
        {
          color_main: model_5_lavender,
          color_details: no_picture,
        },
        {
          color_main: model_5_lemon,
          color_details: no_picture,
        },
        {
          color_main: model_5_menthol,
          color_details: no_picture,
        },
        {
          color_main: model_5_milk,
          color_details: no_picture,
        },
        {
          color_main: model_5_red,
          color_details: no_picture,
        },
        {
          color_main: model_5_taup,
          color_details: no_picture,
        },
        {
          color_main: model_5_violet,
          color_details: no_picture,
        },
        {
          color_main: model_5_white,
          color_details: no_picture,
        },
      ],
    },
    {
      name: 'РЮКЗАК',
      model: model_6,
      details_block: 'none',
      size_block: 'none',
      price: 22500,
      dimension: '24*29*11 СМ',
      colors: [
        {
          color_main: model_6_asphalt,
          color_details: no_picture,
        },
        {
          color_main: model_6_bardic,
          color_details: no_picture,
        },
        {
          color_main: model_6_beige,
          color_details: no_picture,
        },
        {
          color_main: model_6_black,
          color_details: no_picture,
        },
        {
          color_main: model_6_blue,
          color_details: no_picture,
        },
        {
          color_main: model_6_brown,
          color_details: no_picture,
        },
        {
          color_main: model_6_lavender,
          color_details: no_picture,
        },
        {
          color_main: model_6_lemon,
          color_details: no_picture,
        },
        {
          color_main: model_6_menthol,
          color_details: no_picture,
        },
        {
          color_main: model_6_milk,
          color_details: no_picture,
        },
        {
          color_main: model_6_red,
          color_details: no_picture,
        },
        {
          color_main: model_6_taup,
          color_details: no_picture,
        },
        {
          color_main: model_6_violet,
          color_details: no_picture,
        },
        {
          color_main: model_6_white,
          color_details: no_picture,
        },
      ],
    },
    {
      name: 'RITA',
      model: model_7_card_castom,
      details_block: 'none',
      size_block: 'none',
      price: 20000,
      dimension: '42*25*12 СМ',
      colors: [
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
        {
          color_main: model_7,
          color_details: no_picture,
        },
      ],
    },
    {
      name: 'ANNA',
      model: model_8_card_castom,
      details_block: 'none',
      size_block: 'none',
      price: 31000,
      dimension: '38*27*15 СМ',
      colors: [
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
        {
          color_main: model_8,
          color_details: no_picture,
        },
      ],
    },
  ],
  colors: [
    {
      name: 'АСФАЛЬТ',
      color: castom_1,
    },
    {
      name: 'БОРДОВЫЙ',
      color: castom_2,
    },
    {
      name: 'БЕЖЕВЫЙ',
      color: castom_3,
    },
    {
      name: 'ЧЕРНЫЙ',
      color: castom_4,
    },
    {
      name: 'ГОЛУБОЙ',
      color: castom_5,
    },
    {
      name: 'КОРИЧНЕВЫЙ',
      color: castom_6,
    },
    {
      name: 'ЛАВАНДА',
      color: castom_7,
    },
    {
      name: 'ЛИМОННЫЙ',
      color: castom_8,
    },
    {
      name: 'МЕНТОЛОВЫЙ',
      color: castom_9,
    },
    {
      name: 'МОЛОЧНЫЙ',
      color: castom_10,
    },
    {
      name: 'КРАСНЫЙ',
      color: castom_11,
    },
    {
      name: 'ТАУП',
      color: castom_12,
    },
    {
      name: 'ФИОЛЕТОВЫЙ',
      color: castom_13,
    },
    {
      name: 'БЕЛЫЙ',
      color: castom_14,
    },
  ],
  furniture: [
    {
      name: 'ТЕМНЫЙ НИКЕЛЬ',
      model: furniture_1,
    },
    {
      name: 'ЗОЛОТО',
      model: furniture_2,
    },
    {
      name: 'СЕРЕБРО',
      model: furniture_3,
    },
  ],
  size: [
    {
      name: 'S',
      price: 17900,
      dimension: '24*12*12 СМ',
    },
    {
      name: 'M',
      price: 18900,
      dimension: '27*13*12 СМ',
    },
  ],
  main_image: model_1,
  final: {
    model_name: 'SARAH',
    main_image: model_1_asphalt,
    detail_image: no_picture,
    details_block: 'none',
    main_color: 'АСФАЛЬТ',
    detail_color: 'АСФАЛЬТ',
    furniture: 'ТЕМНЫЙ НИКЕЛЬ',
    size: 'S',
    size_block: 'none',
    index_model: 0,
    index_color: 0,
    index_color_detail: 0,
    index_furniture: 0,
    index_size: 0,
    price: 18400,
    dimension: '28*17*8 СМ',
  },
  check_form: false,
};

const castomReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_MODEL:
      state.final.model_name = action.new_name;
      state.final.index_model = action.index_model;
      state.final.details_block = action.new_details;
      state.final.size_block = action.new_size_state;
      state.final.main_image = state.models[action.index_model].colors[0].color_main;
      state.final.detail_image = state.models[action.index_model].colors[0].color_details;
      state.final.price = state.models[action.index_model].price;
      state.final.index_color = 0;
      state.final.index_color_detail = 0;
      state.final.dimension = action.new_dimension;
      return state;
    case CHANGE_COLOR:
      state.final.index_color = action.new_index;
      state.final.main_image =
        state.models[state.final.index_model].colors[state.final.index_color].color_main;
      state.final.main_color = state.colors[action.new_index].name;
      return state;
    case CHANGE_COLOR_DETAIL:
      state.final.index_color_detail = action.new_index_detail;
      state.final.detail_image =
        state.models[state.final.index_model].colors[state.final.index_color_detail].color_details;
      state.final.detail_color = state.colors[action.new_index_detail].name;
      return state;
    case CHANGE_FURNITURE_DETAIL:
      state.final.index_furniture = action.new_index;
      state.final.furniture = action.new_name;
      return state;
    case CHANGE_SIZE:
      state.final.index_size = action.new_index;
      state.final.size = action.new_name;
      state.final.price = state.size[action.new_index].price;
      state.final.dimension = state.size[action.new_index].dimension;
      return state;
    case CHECK_FORM:
      state.check_form = action.new_check;
    default:
      return state;
  }
};

export const changeModelAC = (model_name, index_model, details_block, size_state, dimension) => ({
  type: CHANGE_MODEL,
  new_name: model_name,
  index_model: index_model,
  new_details: details_block,
  new_size_state: size_state,
  new_dimension: dimension,
});
export const changeColorAC = (index_color) => ({ type: CHANGE_COLOR, new_index: index_color });
export const changeColorDetailAC = (index_detail) => ({
  type: CHANGE_COLOR_DETAIL,
  new_index_detail: index_detail,
});
export const changeFurnitureAC = (index, name_furniture) => ({
  type: CHANGE_FURNITURE_DETAIL,
  new_index: index,
  new_name: name_furniture,
});
export const changeSizeAC = (index, name_size) => ({
  type: CHANGE_SIZE,
  new_index: index,
  new_name: name_size,
});
export const checkFormAC = (check) => ({ type: CHECK_FORM, new_check: check });
export default castomReducer;
