import slider1 from '../image/Slider/slider1-9.png';
import slider2 from '../image/Slider/slider2-9.png';
import slider3 from '../image/Slider/slider3-9.png';

let initialState = {
  slider: [
    {
      id: 1,
      slider_image: slider1,
      description_title: 'CALYPSO',
      description_subtitle: 'РОССИЙСКИЙ БРЕНД СУМОК И АКСЕССУАРОВ ИЗ НАТУРАЛЬНОЙ КОЖИ',
    },
    {
      id: 2,
      slider_image: slider2,
      description_title: 'CALYPSO',
      description_subtitle: 'РОССИЙСКИЙ БРЕНД СУМОК И АКСЕССУАРОВ ИЗ НАТУРАЛЬНОЙ КОЖИ',
    },
    {
      id: 3,
      slider_image: slider3,
      description_title: 'CALYPSO',
      description_subtitle: 'РОССИЙСКИЙ БРЕНД СУМОК И АКСЕССУАРОВ ИЗ НАТУРАЛЬНОЙ КОЖИ',
    },
  ],
};
const sliderReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default sliderReducer;
